$base-color: #800000;

:root {
  --logo-scale: 1;
}

body {
  background: linear-gradient(lighten($base-color, 5), $base-color);
}

@function size($size: 100) {
  @return unquote('calc(min(#{$size}vh * var(--logo-scale), calc(#{$size}vw * 0.5879)))');
}

@mixin animateSize($property: height) {
  transition: linear 10ms $property;
}

main {
  min-height: 100vh;
  width: 100%;
  color: white;
  font-family: "Roboto", sans-serif;
}

*::selection {
  background: #b73d2a; /* WebKit/Blink Browsers */
}

*::-moz-selection {
  background: #b73d2a; /* Gecko Browsers */
}

.test {
  position: fixed;
  top: 0;
  left: 0;
  width: 15px;
  height: calc(100vh * var(--logo-scale));
  background-color: green;
}

.logo-spacer {
  height: 100vh;
}

.logo {
  color: black;

  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: calc(100vh * var(--logo-scale));
  width: 100vw;
}

.logo-container {
  text-align: center;
  position: relative;
}

.logo-big {
  position: relative;
  font-family: "Zen Loop";
  font-size: size(100);
  line-height: 1;
  pointer-events: none;

  $top: 33%;
  $bottom: 57%;
  clip-path: polygon(0 0, 100% 0, 100% $top, 0 $top, 0 $bottom, 100% $bottom, 100% 100%, 0 100%);
}

.logo-small {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  width: 100%;
  font-weight: 400;
  font-size: size(13);
  letter-spacing: size(3);
}

.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in Internet Explorer 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}

.content {
  border-top: 2px black solid;
  height: 100vh;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-h {
  display: flex;
  justify-content: center;
}

.h-100vh {
  height: 100vh;
}

.w-100vw {
  width: 100vw;
}

.w-100 {
  width: 100%;
}